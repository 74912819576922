<template>
  <div class="mobile-side-nav lg:hidden fixed w-full h-[60px] bg-[#F0F2F2] shadow-[0_0_8px_rgba(0,0,0,.255)] block bottom-0 z-10 rounded-tl-[8px] rounded-tr-[8px] overflow-hidden">
    <ul class="grid grid-cols-5 w-full text-[12px]">
      <li>
        <router-link to="/" class="flex flex-col items-center py-[8px] h-full">
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6719 8.5623L10.4219 2.88261C10.3067 2.77721 10.1562 2.71875 10 2.71875C9.84384 2.71875 9.69334 2.77721 9.57812 2.88261L3.32812 8.5623C3.26523 8.6217 3.21486 8.69308 3.17998 8.77224C3.14509 8.8514 3.1264 8.93674 3.125 9.02324V16.2498C3.125 16.4156 3.19085 16.5745 3.30806 16.6917C3.42527 16.809 3.58424 16.8748 3.75 16.8748H16.25C16.4158 16.8748 16.5747 16.809 16.6919 16.6917C16.8092 16.5745 16.875 16.4156 16.875 16.2498V9.02324C16.8736 8.93674 16.8549 8.8514 16.82 8.77224C16.7851 8.69308 16.7348 8.6217 16.6719 8.5623V8.5623Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>Home</p>
        </router-link>
      </li>
      <li>
        <router-link to="/payroll" class="flex flex-col items-center py-[8px] h-full">
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.9375 8.125H14.0625" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.9375 10.625H14.0625" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 16.25V4.375C2.5 4.20924 2.56585 4.05027 2.68306 3.93306C2.80027 3.81585 2.95924 3.75 3.125 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V16.25L15 15L12.5 16.25L10 15L7.5 16.25L5 15L2.5 16.25Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>Payroll</p>
        </router-link>
      </li>
      <li>
        <router-link to="/pension" class="flex flex-col items-center py-[8px] h-full">
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.75 15.625C13.75 16.1223 13.5525 16.5992 13.2008 16.9508C12.8492 17.3025 12.3723 17.5 11.875 17.5C11.3777 17.5 10.9008 17.3025 10.5492 16.9508C10.1975 16.5992 10 16.1223 10 15.625V10.625" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5227 10.625C2.43612 10.6254 2.3504 10.6077 2.27114 10.5728C2.19187 10.538 2.12082 10.4868 2.06261 10.4227C2.0044 10.3586 1.96033 10.283 1.93327 10.2007C1.9062 10.1185 1.89675 10.0314 1.90552 9.9453C2.08353 7.92148 3.01329 6.03777 4.5115 4.66557C6.0097 3.29336 7.96763 2.53223 9.99927 2.53223C12.0309 2.53223 13.9888 3.29336 15.487 4.66557C16.9852 6.03777 17.915 7.92148 18.093 9.9453C18.1018 10.0314 18.0923 10.1185 18.0653 10.2007C18.0382 10.283 17.9941 10.3586 17.9359 10.4227C17.8777 10.4868 17.8067 10.538 17.7274 10.5728C17.6481 10.6077 17.5624 10.6254 17.4758 10.625H2.5227Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.875 10.625C6.875 5 10 2.5 10 2.5C10 2.5 13.125 5 13.125 10.625" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>Pension</p>
        </router-link>
      </li>
      <li>
        <router-link to="/Taxnfh" class="flex flex-col items-center py-[8px] h-full">
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3.125V16.875" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.125 16.875H11.875" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.375 6.875L15.625 4.375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.875 13.125C1.875 14.5078 3.4375 15 4.375 15C5.3125 15 6.875 14.5078 6.875 13.125L4.375 6.875L1.875 13.125Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.125 10.625C13.125 12.0078 14.6875 12.5 15.625 12.5C16.5625 12.5 18.125 12.0078 18.125 10.625L15.625 4.375L13.125 10.625Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>Tax & NHF</p>
        </router-link>
      </li>
      <li>
        <router-link to="/profile" class="flex flex-col items-center py-[8px] h-full">
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#626669" stroke-width="1.3" stroke-miterlimit="10"/>
            <path d="M1.73438 15.6249C2.55567 14.6461 3.58138 13.8589 4.73942 13.3189C5.89746 12.7789 7.15973 12.499 8.4375 12.499C9.71527 12.499 10.9775 12.7789 12.1356 13.3189C13.2936 13.8589 14.3193 14.6461 15.1406 15.6249" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1875 11.875C17.8779 11.875 18.4375 11.3154 18.4375 10.625C18.4375 9.93464 17.8779 9.375 17.1875 9.375C16.4971 9.375 15.9375 9.93464 15.9375 10.625C15.9375 11.3154 16.4971 11.875 17.1875 11.875Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1875 9.375V8.4375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.1016 10L15.2969 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.1016 11.25L15.2969 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1875 11.875V12.8125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.2734 11.25L19.0781 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.2734 10L19.0781 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>Profile</p>
        </router-link>
      </li>
      <li>
        <router-link to="/logout" class="flex flex-col items-center py-[8px] h-full">
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#626669" stroke-width="1.3" stroke-miterlimit="10"/>
            <path d="M1.73438 15.6249C2.55567 14.6461 3.58138 13.8589 4.73942 13.3189C5.89746 12.7789 7.15973 12.499 8.4375 12.499C9.71527 12.499 10.9775 12.7789 12.1356 13.3189C13.2936 13.8589 14.3193 14.6461 15.1406 15.6249" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1875 11.875C17.8779 11.875 18.4375 11.3154 18.4375 10.625C18.4375 9.93464 17.8779 9.375 17.1875 9.375C16.4971 9.375 15.9375 9.93464 15.9375 10.625C15.9375 11.3154 16.4971 11.875 17.1875 11.875Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1875 9.375V8.4375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.1016 10L15.2969 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.1016 11.25L15.2969 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1875 11.875V12.8125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.2734 11.25L19.0781 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.2734 10L19.0781 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>Profile</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>
  
<script>
export default {
  name: 'MobileNav',
  props: {
    menu: Object
  },
  computed: {
    currentRoute(){
      return this.$router.currentRoute.matched;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .router-link-exact-active{
  color: #003b3d;
  path{
    stroke: #003b3d;
  }
 }
</style>
    