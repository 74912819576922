/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: () => import(/* webpackChunkName: "payroll" */ '../views/PageNotFound.vue'),
    meta: {
      authorized_token: "all",
    }
  },
  {
    path: '/invite/:id',
    name: 'invite',
    component: () => import(/* webpackChunkName: "payroll" */ '../views/InviteView.vue'),
    meta: {
      authorized: false,
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      authorized: true,
    }
  },
  {
    path: '/payroll',
    name: 'payroll',
    component: () => import(/* webpackChunkName: "payroll" */ '../views/PayrollView.vue'),
    meta: {
      authorized: true,
    }
  },
  {
    path: '/payroll/:id',
    name: 'payroll.id',
    component: () => import(/* webpackChunkName: "payroll_id" */ '../views/PayrolldetailView.vue'),
    meta: {
      authorized: true,
    }
  },
  {
    path: '/payroll/:id/reciept',
    name: 'reciept',
    component: () => import(/* webpackChunkName: "payroll_id" */ '../views/RecieptView.vue'),
    meta: {
      authorized: true,
    }
  },
  {
    path: '/pension',
    name: 'pension',
    component: () => import(/* webpackChunkName: "pension" */ '../views/PensionView.vue'),
    meta: {
      authorized: true,
    }
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
    meta: {
      authorized: true,
    }
  },
  {
    path: '/Taxnfh',
    name: 'Taxnfh',
    component: () => import(/* webpackChunkName: "payroll" */ '../views/TaxView.vue'),
    meta: {
      authorized: true,
    }
  },
  {
    path: '/signup/:id',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignupView.vue'),
    meta: {
      authorized: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      authorized: false,
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  const authorized = localStorage.getItem('token') ? true : false

  if(!to.meta.authorized_token == 'all'){
    // console.log('red');
  }else if(!authorized && to.meta.authorized == true){
    return {name: 'login'}
  }else if(authorized && to.meta.authorized == false){
    return {name: 'home'}
  }
})
export default router
