import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

window.axios = require('axios');

window.axios.defaults.baseURL = "https://payroll.platoonco.com/api/"
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';

createApp(App).use(store).use(vue3GoogleLogin, {
    clientId: '1076378765528-mg83j8af064qdkfm9hdur6s3ltmgj7f6.apps.googleusercontent.com',
}).use(router).use(VueSweetalert2).mount('#app')
