<template>
    <div class="flex">
        <SideNav :profile="data" />
        <MobileNav />
        <div class="content grow bg-[#F0F2F2] lg:ml-[260px] ml-[0] pb-[100px]">
            <HeroCard color="#003b3d">
                <div class="hero--info_user-account absolute right-0 top-[12px]">
                    <div class="home--img_user-account relative">
                        <button @click="user = !user" class="w-[40px] h-[40px] bg-[#F0F2F2] rounded-[64px] flex items-center justify-center">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#626669" stroke-width="1.3" stroke-miterlimit="10"/>
                                <path d="M1.73438 15.6249C2.55567 14.6461 3.58138 13.8589 4.73942 13.3189C5.89746 12.7789 7.15973 12.499 8.4375 12.499C9.71527 12.499 10.9775 12.7789 12.1356 13.3189C13.2936 13.8589 14.3193 14.6461 15.1406 15.6249" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.1875 11.875C17.8779 11.875 18.4375 11.3154 18.4375 10.625C18.4375 9.93464 17.8779 9.375 17.1875 9.375C16.4971 9.375 15.9375 9.93464 15.9375 10.625C15.9375 11.3154 16.4971 11.875 17.1875 11.875Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.1875 9.375V8.4375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.1016 10L15.2969 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.1016 11.25L15.2969 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.1875 11.875V12.8125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.2734 11.25L19.0781 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.2734 10L19.0781 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div class="home--status_user-account border-2 border-white w-[12px] h-[12px] rounded-full bg-[#56CA00] absolute right-0 bottom-0"></div>
                        <div v-show="user" class="dropdown px-[10px] absolute w-[157px] h-[86px] rounded-[8px] py-[16px] bg-white right-[0px] mt-[16px] shadow-lg shadow-[#00000014]">
                            <button type="button" class="block w-full py-[8px] rounded-[10px] bg-[#FF4C51] text-white" @click="logout()">
                                <span>Logout</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="hero--greeting_user-account text-center pt-[85px]">
                    <span class="text-[32px] font-bold text-white">{{ timeOfDay+' '+data.firstname  }}</span>
                </div>
            </HeroCard>
            <div class="briefing pl-[24px] pr-[18px] lg:h-[163px] h-auto">
                <div class="wrap flex lg:flex-row flex-col w-[100%] max-w-[1138px] lg:h-[215px] h-auto rounded-[8px] translate-y-[-68px]">
                    <div class="box flex-none lg:w-[66.5%] w-full mr-[17px] lg:mb-[0] mb-[24px] rounded-[8px] flex lg:flex-row flex-col overflow-hidden bg-white">
                        <div class="info lg:w-[50%] w-auto pl-[24px] pt-[21px] lg:pb-[0] pb-[16px] flex">
                            <div class="icon w-[35px] h-[35px] mr-[9px] rounded-full bg-[#F0F2F2] flex items-center justify-center uppercase text-[14px]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#626669" stroke-width="1.3" stroke-miterlimit="10"/>
                                    <path d="M1.73438 15.6249C2.55567 14.6461 3.58138 13.8589 4.73942 13.3189C5.89746 12.7789 7.15973 12.499 8.4375 12.499C9.71527 12.499 10.9775 12.7789 12.1356 13.3189C13.2936 13.8589 14.3193 14.6461 15.1406 15.6249" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.1875 11.875C17.8779 11.875 18.4375 11.3154 18.4375 10.625C18.4375 9.93464 17.8779 9.375 17.1875 9.375C16.4971 9.375 15.9375 9.93464 15.9375 10.625C15.9375 11.3154 16.4971 11.875 17.1875 11.875Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.1875 9.375V8.4375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.1016 10L15.2969 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.1016 11.25L15.2969 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.1875 11.875V12.8125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.2734 11.25L19.0781 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.2734 10L19.0781 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="text grow relative">
                                <div class="mb-[12px]">
                                    <p class="font-medium text-[#2E3339]">{{ data.firstname }} {{ data.lastname }}</p>
                                    <p class="font-normal text-[#74787A]">{{ data.email }}</p>
                                </div>
                                <div class="lg:mb-[0] mb-[16px]">
                                    <p class="font-medium text-[#2E3339]">{{ grade.name }}</p>
                                    <p class="font-normal text-[#74787A]">{{ group.name }}</p>
                                </div>
                                <router-link to="/profile" class="lg:text-[14px] text-[11px] lg:absolute bottom-[16px] font-medium rounded-[200px] text-[#003b3d] bg-[#F0F2F2] h-[41px] leading-[41px] items-center table">
                                    <span class="px-[17px] float-left">Update Personal details</span>
                                    <svg class="ml-[10px] translate-x-[-10px] translate-y-[10px]" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.125 10.5H16.875" stroke="#003b3d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.25 4.875L16.875 10.5L11.25 16.125" stroke="#003b3d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </router-link>
                            </div>
                        </div>
                        <div class="info lg:w-[50%] w-auto pl-[24px] pt-[21px] lg:pb-[0] pb-[16px]  flex">
                            <!-- <div class="icon w-[35px] h-[35px] mr-[9px] rounded-full bg-[#F0F2F2]"></div> -->
                            <div class="text grow relative">
                                <div>
                                    <p class="font-medium text-[#2E3339] text-[16px]">Bank Details</p>
                                    <p class="font-normal text-[#74787A] text-[14px]">Update your bank details</p>
                                </div>
                                <div>
                                    <p class="font-bold text-[24px] leading-[56px] text-[#222222DE]">{{ bank.bank }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box flex-auto lg:w-[33.5%] h-auto lg:pb-[0] pb-[16px] bg-white rounded-[8px]">
                        <div class="info info2 pl-[31px] pt-[21px] h-full flex">
                            <div class="text grow relative">
                                <div>
                                    <p class="font-medium text-[#2E3339] text-[16px]">Last Salary Pay</p>
                                    <p class="font-normal text-[#74787A]">Gross: N{{ formatPrice(payment) }}</p>
                                </div>
                                <div class="lg:mb-[0] mb-[16px]">
                                    <p class="font-bold text-[24px] leading-[56px] text-[#222222DE]">N{{ formatPrice(payment) }}</p>
                                </div>
                                <router-link to="/payroll" class="lg:text-[14px] text-[11px] lg:absolute bottom-[16px] font-medium rounded-[200px] text-[#003b3d] bg-[#F0F2F2] h-[41px] leading-[41px] items-center table">
                                    <span class="px-[17px] float-left">View Salary History</span>
                                    <svg class="ml-[10px] translate-x-[-10px] translate-y-[10px]" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 10.5H16.875" stroke="#003b3d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.25 4.875L16.875 10.5L11.25 16.125" stroke="#003b3d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </router-link>
                            </div>
                            <div class="icon w-[48px] h-[48px] mr-[24px] rounded-full bg-[#F0F2F2] flex items-center justify-center">
                                <!-- <img src="@/assets/icons/UsersThree.svg" alt="icon"> -->
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0006 19.5714C16.3675 19.5714 18.2863 17.6526 18.2863 15.2857C18.2863 12.9188 16.3675 11 14.0006 11C11.6336 11 9.71484 12.9188 9.71484 15.2857C9.71484 17.6526 11.6336 19.5714 14.0006 19.5714Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M21.2861 12.7139C22.2844 12.7122 23.2693 12.9438 24.1622 13.3903C25.0551 13.8367 25.8313 14.4857 26.429 15.2853" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.57129 15.2853C2.16893 14.4857 2.94516 13.8367 3.83807 13.3903C4.73097 12.9438 5.71585 12.7122 6.71415 12.7139" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.8291 23.4286C8.39356 22.2726 9.27133 21.2984 10.3624 20.617C11.4535 19.9355 12.7141 19.5742 14.0005 19.5742C15.287 19.5742 16.5475 19.9355 17.6386 20.617C18.7297 21.2984 19.6075 22.2726 20.172 23.4286" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.71428 12.7146C6.06354 12.7152 5.42605 12.5307 4.87629 12.1825C4.32654 11.8343 3.88723 11.3369 3.60969 10.7483C3.33215 10.1597 3.22785 9.50426 3.30897 8.85859C3.39009 8.21293 3.65329 7.60368 4.0678 7.10204C4.48231 6.6004 5.03103 6.22708 5.64983 6.02571C6.26863 5.82434 6.93196 5.80323 7.56231 5.96484C8.19266 6.12646 8.764 6.46413 9.20958 6.93839C9.65515 7.41266 9.95655 8.00393 10.0786 8.64313" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.9219 8.64313C18.0439 8.00393 18.3453 7.41266 18.7909 6.93839C19.2364 6.46413 19.8078 6.12646 20.4381 5.96484C21.0685 5.80323 21.7318 5.82434 22.3506 6.02571C22.9694 6.22708 23.5181 6.6004 23.9326 7.10204C24.3472 7.60368 24.6103 8.21293 24.6915 8.85859C24.7726 9.50426 24.6683 10.1597 24.3907 10.7483C24.1132 11.3369 23.6739 11.8343 23.1241 12.1825C22.5744 12.5307 21.9369 12.7152 21.2862 12.7146" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="vue-chartjs-wrapper h-[412px] pl-[24px] pr-[18px]">
                <div class="bar-chart h-full max-w-[1138px] rounded-[8px] bg-white flex">
                    <div class="bar--chart_diagram w-[71%] h-full border-r-[1px] pl-[24px] pr-[35px] py-[19px] border-[#2222221F]">
                        <div class="bar--chart_header mb-[70px]">
                            <p class="font-medium text-[#2E3339] text-[16px] leading-[32px]">Salary History</p>
                            <p class="font-normal text-[#74787A] text-[14px] leading-[32px]">Showing results for last 12 months</p>
                        </div>
                        <Bar
                            id="my-chart-id"
                            :options="chartOptions"
                            :data="chartData"
                        />
                    </div>
                    <div class="bar--chart_history w-[29%] px-[30px] py-[19px]">
                        <div class=" mb-[39px]">
                            <p class="font-bold text-[24px] leading-[32.02px] text-[#222222DE]">₦482,850</p>
                            <p class="font-normal text-[#74787A] text-[14px]">Total payments made this year</p>
                        </div>
                        <div class="bar--chart_info pr-[12px]">
                            <table class="table-auto w-full border-spacing-2">
                                <tbody>
                                    <tr v-for="data in tableData" :key="data.id" class="h-[46px] border-t-[1px] border-t-[#2222221F] text-[14px]">
                                        <td><div class="bar--chart_info-color w-[13.33px] h-[13.33px] rounded-full" :style="'background: '+data.iconColor"></div></td>
                                        <td>{{ data.title }}</td>
                                        <td class="text-right pr-[24px] font-semibold">{{ data.amount }}</td>
                                        <td class="font-semibold">{{ data.percentage }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import SideNav from "@/components/shared/partials/SideNav.vue"
import HeroCard from "@/components/shared/partials/HeroCard.vue"
import MobileNav from "@/components/shared/partials/MobileNav.vue"
import router from '@/router'
// import { Bar } from 'vue-chartjs'
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'HomeView',
    components: {
        // Bar,
        SideNav,
        HeroCard,
        MobileNav,
        router
    },
    data(){
        return {
            data: {},
            grade: {
                name: '',
                salary: 0,
            },
            group: {
                name: '',
            },
            bank: {},
            payment: 0,
            user: false,
            myDate: new Date(),
        }
    },
    mounted() {
        window.axios.get('user/profile', {
        headers: {"Authorization" : localStorage.getItem('type')+" "+localStorage.getItem('token')}
        })
        .then(res => {
            this.data = res.data.data;
            this.grade = res.data.data.grade; 
            this.group = res.data.data.group;
            this.bank = res.data.data.bank_data;

            this.payment = (res.data.data.payments).length ? res.data.data.payments.reduce((accumulator, currentObject) => accumulator + currentObject.amount, 0) : 0;
        })
    },
    methods: {
        logout(){
            window.axios.get('user/logout')
            .then(res => {
                localStorage.clear();
                return router.push({name: 'login'});
            })
        },
        formatPrice(value) {
            let val = (value/1);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    computed: {
        timeOfDay() {
            const hrs = this.myDate.getHours();
            if (hrs < 12)
                return 'Good Morning';
                else if (hrs >= 12 && hrs < 17)
                return 'Good Afternoon';
                else if (hrs >= 17 && hrs <= 24)
                return 'Good Evening';
        }
    }
}
</script>

<style lang="scss" scoped>
    .info .text, 
    .info a{
        font-size: 14px;
    }
    #my-chart-id{
        height: 225px !important;
    }
    .bar-chart, .briefing .wrap{
        margin: 0 auto;
    }

    html, body{
        background: #F0F2F2;
    }

    @media (max-width: 1200px){
        .briefing{
            height:auto;
            .wrap{
                height: auto;
                flex-flow: column;
                a{
                    position: static;
                }
                .box{
                    width: 100% !important;
                    padding-bottom: 16px;
                    .text div:nth-of-type(2){
                        margin-bottom: 16px;
                    }
                }
                .box:last-of-type{
                    margin-top: 16px;
                }
            }
        }
    }
    .preloader {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 50%;
        top: 50%;
        min-height: 102px;
        transform: translateX(-50%) translateY(-50%);
    }
    .preloader .small-circle {
        stroke-dasharray: 210;
        stroke-dashoffset: 210;
        transform-origin: 50%;
        animation: 1s draw-small infinite alternate;
    }
    @keyframes draw-small {
        0% { stroke-dashoffset: 0; transform: rotate(0deg); }
        100% { stroke-dashoffset: 210; transform: rotate(360deg); }
    }
    .preloader .big-circle {
        stroke-dasharray: 240;
        stroke-dashoffset: 240;
        transform-origin: 50%;
        animation: 1s draw-big infinite alternate 0.5s;
    }
    @keyframes draw-big {
        0% { stroke-dashoffset: 0; transform: rotateY(180deg) rotate(360deg); }
        100% { stroke-dashoffset: 240; transform: rotateY(180deg) rotate(0deg); }
    }
</style>